<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card class="shadow-off" :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn v-if="$nova.hasRight(right, 'add')" rounded color="btnColor" @click="queriesDilog = true;queriesObject = {};error = {}; " elevation="0" small class="mr-2 white--text">
                <v-icon class="mr-1">mdi-plus</v-icon>New
              </v-btn>
              <v-btn icon small @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
              <v-spacer></v-spacer>
              <lb-string label="Search" outlined hidedetails v-model="seachList"></lb-string>
            </div>
          </v-card-text>
          <v-card-text>
            <v-data-table class="FC-Table border-on radius-small" :headers="queriesHeader" :items="queriesData" hide-default-footer dense :items-per-page="-1"
              :search="seachList">
              <template #[`item.no`]="{ item, index }">
                <tr>
                  <td>{{ index + 1 }}</td>
                </tr>
              </template>

              <template v-slot:[`item.stage`]="{ item }">
                <div class="text-center">
                  <v-chip x-small label v-if="item.stage === 1" color="lightgreen" class="teal--text">Close</v-chip>
                  <v-chip x-small label v-else color="lightblue" class="Tabblue--text">Open</v-chip>
                </div>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div class="text-center">
                  <v-chip x-small label v-if="item.type === 'technical'" color="">Technical</v-chip>
                  <v-chip x-small label v-else-if="item.type === 'functional'" color="">Functional</v-chip>
                  <v-chip x-small label v-else>NA</v-chip>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center justify-center">
                  <v-btn @click="
                    $nova.gotoLink({ path: 'support/' + item._id })
                  " class="view-on-hover-item-d mx-0 px-0" icon><v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <Conversation heading="Conversation" width="600" :loading="loading" :recurring="true"
                    :recurringText="recurringContent" :notify="false" :projectid="item._id" :getApi="getURL"
                    :sendApi="sendURL" />
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="queriesDilog" max-width="600" persistent>
      <v-card class="" :loading="queriesloading" :disabled="queriesloading" loader-height="2">
        <v-card-text class=" font-weight-bold d-flex align-center py-2 px-4">
          <span class="subtitle-1 font-weight-bold" v-if="queriesObject._id">Update</span>
          <span class="subtitle-1 font-weight-bold" v-else>Add</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="queriesDilog = false" class="ml-2 "><v-icon>mdi-close</v-icon></v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-0">
              <lb-string label="Request" :isRequired="true" v-model="queriesObject.issue" :error="errors['issue']" />
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <lb-textarea v-model="queriesObject.description" label="Description"
                :error="errors['description']" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="fbcPrimary"  class="white--text" v-if="queriesObject._id" @click="productUpdate()">Update</v-btn>
          <v-btn small color="fbcPrimary" class="white--text" v-else @click="createQuerie()">Create</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Conversation from "../common/Conversation.vue";
export default {
  name: "helpdesk_list",
  data() {
    return {
      right: "helpdesk",
      getURL: "/v2/bot/analysis/getbotconversation/",
      sendURL: "/v2/bot/analysis/addbotconversation",
      recurringContent: "Is it possible to append comment in requirements sdsdfs sdfsdf sdfsd asdsadawd ",
      loading: false,
      errors: {},
      queriesObject: {},
      queriesDilog: false,
      queriesloading: false,
      queriesHeader: [
        { text: "No", value: "no", sortable: false },
        { text: "Request", value: "issue", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Type", value: "type", sortable: false, align: "center" },
        { text: "Stage", value: "stage", sortable: false, align: "center" },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      seachList: null,
      queriesData: [],
    };
  },
  components: {
    // Conversation,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  props: {
    project: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    typenot: {
      type: String,
      default: "",
    },
  },
  methods: {
    refreshData() {
      this.loading = true;
      let nfltr = { modulename: this.$route.meta.modulename };
      if(this.type) nfltr.type = this.type;
      if(this.typenot) nfltr.typenot = this.typenot;
      if(this.project) nfltr.project = this.project;
      this.axios
        .post("/v2/helpdesk/queries/getqueriesbyclient", { filter: nfltr })
        .then((dt) => {
          this.queriesData = dt.data.data;
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createQuerie() {
      this.queriesloading = true;
      // console.log(this.$route.mata.modulename, "this.$route.mata.modulename");
      this.queriesObject.modulename = this.$route.meta.modulename;
      this.axios
        .post("/v2/helpdesk/queries/add", { data: this.queriesObject })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "New Query Added successfully");
            this.queriesDilog = false;
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Add Query");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.queriesloading = false;
        });
    },
    QuerieUpdate() {
      this.loading = true;
      this.queriesObject.modulename = this.$store.state.modulename;
      this.axios
        .post("/v2/helpdesk/queries/edit/" + this.queriesObject._id, {
          data: this.queriesObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.refreshData();
            this.$store.commit("sbSuccess", "New Query Added successfully");
            this.refreshData();
            this.queriesDilog = false;
          } else {
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Add Query");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
