<template>
  <div class="">
    <QueriesList :project="project" typenot="functional"></QueriesList>
  </div>
</template>

<script>
import QueriesList from '../../../views/common/QueriesList.vue';
export default {
  name: 'financialclose_virtualconcierge_list',
  // props: ['props'],
  data: function () {
    return {
      project: "",
    }
  },
  components: {
    QueriesList
  },
  created() {
    this.init();
    this.$store.commit("setProjectName", "");
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.project = this.$route.params.id;
    },

  },
  watch: {
  }
}
</script>
